import React from "react";
import PropTypes from "prop-types";
import getLogo from "Utils/logoMap.js";
import "./styles.scss";

const ResultsLogo = ({provider}) => {
    const LogoComponent = getLogo(provider);
    return (
        <div className="logo-container">
            <LogoComponent />
        </div>

    );
};

ResultsLogo.propTypes = {
    provider: PropTypes.string.isRequired,
};

export default ResultsLogo;