import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Signature = ({image}) => (
    <img className="yodel-signature" src={`data:image/png;base64, ${image}`} alt="Signature" />
);

Signature.propTypes = {
    image: PropTypes.string.isRequired,
};

export default Signature;