import React from "react";
import TheGeeksLogo from "Assets/Logos/TheGeeksBlack.png";
import "./styles.scss";

const AboutUs = () => (
    <div className="init-container">
        <div> About this widget</div>
        This is a <span>third-party widget</span> designed to enhance your Gnatta experience. To setup tracking, you’ll need to obtain <span>unique API credentials</span> from each courier and pass them to your Gnatta account manager, who’ll work with us to get this widget up and running. 
        <br />
        <br />If you have any suggestions or improvements, please pop them along to <a href="mailto:hello@thegeeks.io?subject = Universal Tracking Feedback"><span>hello@thegeeks.io</span></a>, or you can ask your Gnatta account manager to pass on your feedback directly. Thanks!
        <div className="geeks-logo">
            <img src={TheGeeksLogo} alt="TheGeeks Logo"/>
        </div>
    </div>
);

export default AboutUs;
