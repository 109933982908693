import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Row from "Components/Card/Row/index.js";
import DateIcon from "Assets/Icons/Date.png";
import LocationIcon from "Assets/Icons/Location.png";
import StatusIcon from "Assets/Icons/Status.png";

const EventsHeader = ({dateTime, location, description, onCopy}) => (
    <table>
        <tbody>
            {dateTime && <Row
                history
                copy
                onCopy={onCopy}
                label="Date"
                value={moment(dateTime).local().format("YYYY-MM-DD HH:mm")}
                icon={DateIcon}
            />}
            {location && <Row 
                history
                copy
                onCopy={onCopy}
                label="Location"
                value={location}
                icon={LocationIcon}
            />}
            {description && <Row
                history
                copy
                onCopy={onCopy}
                nowrap
                label="Status"
                value={description}
                icon={StatusIcon}
            />}
        </tbody>
    </table>
);

EventsHeader.propTypes = {
    dateTime: PropTypes.string.isRequired,
    location: PropTypes.string,
    description: PropTypes.string.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default EventsHeader;