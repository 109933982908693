import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "Assets/Icons/Search.png";
import "./styles.scss";

const SearchBox = ({search}) => {
	const [trackingNumber, setTrackingNumber] = useState("");

	const onSearch = (e) => {
		e.preventDefault();
		search(trackingNumber);
    };
    
    return (
        <div className="search-box-container">
            <form onSubmit={onSearch}>
                <div className="search-container">
                    <input
                        value={trackingNumber}
                        onChange={((e, v) => setTrackingNumber(e.target.value))}
                        placeholder="Tracking Number..."
                    />
                    <button onClick={onSearch}>
                        <img src={SearchIcon} alt="search icon" />
                    </button>
                </div>
            </form>
        </div>
    );
};

SearchBox.propTypes = {
    search: PropTypes.func.isRequired,
};

export default SearchBox;
