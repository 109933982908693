import React from "react";
import PropTypes from "prop-types";
import Card from "Components/Card/index.js";
import Row from "Components/Card/Row/index.js";
import TrackingIcon from "Assets/Icons/TrackingNumber.png";
import LinkIcon from "Assets/Icons/Link.png";
import InstructionsIcon from "Assets/Icons/Instructions.png";
import CompanyIcon from "Assets/Icons/Company.png";
import { DODDLETRACKINGURL } from "Constants/tracking.js"

const DoddleResults = ({data, onCopy}) => (
	<React.Fragment>
		<Card header="Summary" first>
			{data.trackingNumber && <Row 
				copy
				onCopy={onCopy}
				label="Tracking No."
				value={data.trackingNumber}
				icon={TrackingIcon}
			/>}
			{data.trackingNumber && <Row
				copy
				onCopy={onCopy}
				link
				label="Link"
				value={DODDLETRACKINGURL + data.trackingNumber}
				icon={LinkIcon}
			/>}
			{data.type && <Row
				copy
				onCopy={onCopy}
				nowrap
				label="Type"
				value={data.type}
				icon={InstructionsIcon}
			/>}
			{data.retailer && <Row
				copy
				onCopy={onCopy}
				label="Retailer"
				value={data.retailer}
				icon={CompanyIcon}
			/>}
		</Card>
	</React.Fragment>
);

DoddleResults.propTypes = {
    data: PropTypes.object.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default DoddleResults;