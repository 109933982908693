import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import "./styles.scss";

const EventDateTime = ({dateTime}) => (
    <React.Fragment>
        {dateTime &&
            <div className="datetime">{moment(dateTime).local().format("YYYY-MM-DD HH:mm")}</div>
        }
    </React.Fragment>
);

EventDateTime.propTypes = {
    dateTime: PropTypes.string,
};

export default EventDateTime;