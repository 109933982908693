import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Error = ({message}) => (
	<div className="error">
        {message}
	</div>
);

Error.propTypes = {
    message: PropTypes.string.isRequired,
};

export default Error;
