import React from "react";
import PropTypes from "prop-types";
import Header from "Components/Card/Header/index.js";
import "./styles.scss";

const Card = ({header, first, signature, children}) => (
	<div className={`card ${first ? "first-card" : ""}`}>
        <Header text={header} />
        <div className="card-container">
            <table>
                <tbody>
                    {children}
                </tbody>
            </table>
            {signature}
        </div>
	</div>
);

Card.propTypes = {
    children: PropTypes.node.isRequired,
    signature: PropTypes.node,
    header: PropTypes.string.isRequired,
    first: PropTypes.bool,
};

Card.defaultProps = {
    first: false,
};

export default Card;