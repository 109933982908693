import React from "react";
import PropTypes from "prop-types";
import Header from "Components/Card/Header/index.js";
import Subheader from "Components/Card/Subheader/index.js";
import EventsHeader from "Components/Card/EventsHeader/index.js";
import EventList from "Components/Card/EventList/index.js";
import "./styles.scss";

const Events = ({header, subheader, events, onCopy}) => (
	<div className="card card-event">
        <Header text={header} />
        <Subheader text={subheader} />
        <div className="card-container">
            {events[0].location || events[0].description ? <EventsHeader 
                dateTime={events[0].dateTime}
                location={events[0].location}
                description={events[0].description}
                onCopy={onCopy}
            /> : null}
        </div>
        <div className="card-subcontainer">
            <EventList events={events} />
        </div>
	</div>
);

Events.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default Events;