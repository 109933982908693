import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Subheader = ({text}) => (
	<div className="card-subheader">
        {text}
	</div>
);

Subheader.propTypes = {
    text: PropTypes.string.isRequired,
};

export default Subheader;