import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Header = ({text}) => (
	<div className="card-header">
        {text}
	</div>
);

Header.propTypes = {
    text: PropTypes.string.isRequired,
};

export default Header;