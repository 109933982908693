import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Signature = ({image}) => (
    <img className="hermes-signature" alt="Proof of Delivery" src={`data:image/png;base64,${image}`}/>
);

Signature.propTypes = {
    image: PropTypes.string.isRequired,
};

export default Signature;
