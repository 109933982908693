import React from "react";
import Logo from "Assets/Logos/P2PGreyscale.png";
import "./styles.scss";

const P2PLogo = () => (
	<div className="p2p-logo-container">
        <img src={Logo} alt="P2P Logo" />
    </div>
);

export default P2PLogo;