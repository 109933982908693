import React from "react";
import PropTypes from "prop-types";
import OpenLink from "Assets/Icons/OpenLink.png";
import "./styles.scss";

const Link = ({value}) => (
	<div className="open-link-container">
        <a target="_blank" rel="noopener noreferrer" href={value}>
            <img src={OpenLink} alt="Open Link Icon" />
        </a>
    </div>
);

Link.propTypes = {
    value: PropTypes.string.isRequired,
};

export default Link;