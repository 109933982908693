import React from "react";
import PropTypes from "prop-types";
import CopyIcon from "Assets/Icons/Copy.png";
import "./styles.scss";

const Copy = ({history, value, onCopy}) => (
    <div className="icon">
        <button onClick={(e) => onCopy(e, value)} className={`${history ? "history" : ""}`}>
            <img src={CopyIcon} alt="copy icon" />
        </button>
    </div>
);

Copy.propTypes = {
    history: PropTypes.bool,
    onCopy: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
};

Copy.defaultProps = {
    history: false,
};

export default Copy;
