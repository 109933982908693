import RMLogo from "Components/Results/RM/Logo/index.js";
import YodelLogo from "Components/Results/Yodel/Logo/index.js";
import P2PLogo from "Components/Results/P2P/Logo/index.js";
import DoddleLogo from "Components/Results/Doddle/Logo/index.js";
import HermesLogo from "Components/Results/Hermes/Logo/index.js";
import DPDLogo from "Components/Results/DPD/Logo/index.js";


const TYPES = {
    DPD: DPDLogo,
    P2P: P2PLogo,
    Yodel: YodelLogo,
    RoyalMail: RMLogo,
    Doddle: DoddleLogo,
    Hermes: HermesLogo,
};
    
const getLogo = (provider) => {
    return TYPES[provider] || null;    
};
    
export default getLogo;