import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const EventLocationNote = ({value}) => (
    <React.Fragment>
        {value &&
            <div className="location-note">{value}</div>
        }
    </React.Fragment>
);

EventLocationNote.propTypes = {
    value: PropTypes.string,
};

export default EventLocationNote;