import React from "react";
import Logo from "Assets/Logos/HermesGreyscale.png";
import "./styles.scss";

const HermesLogo = () => (
	<div className="hermes-logo-container">
        <img src={Logo} alt="Hermes Logo" />
    </div>
);

export default HermesLogo;