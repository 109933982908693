import React from "react";
import Cookies from "js-cookie";
import SearchBox from "Components/SearchBox/index.js";
import Footer from "Components/Footer/index.js";
import Tracking from "Components/Tracking/index.js";
import HomePage from "Components/HomePage/index.js";
import * as api from "Utils/api.js";
import "./styles.scss";

const App = class extends React.Component {
	constructor(props) {
        super(props);
        this.apiRoute = "/api/tracking/";
        this.state = {
            results: null,
            error: null,
            searching: false,
            bootstrap: true,
            unauthorised: false
        };
    }

    componentDidMount() {
        this.checkCookies();
    }
    
    checkCookies = () => {
        if(Cookies.get("TG.UT.Auth") === undefined || Cookies.get("TG.UT.Client") === undefined) {
            // Prevents searchbox from rendering if cookie values aren't present
            // If they set dummy values in the cookies, they'll get an unauthorised response when they attempt to search
            this.setState({ unauthorised: true, bootstrap: false, error: "Unauthorised" });
        } else {
            const trackingCookie = Cookies.get("TG.UT.Tracking");
            if(trackingCookie) {
                this.search(trackingCookie);
            }
        }
    }

	search = async (trackingNumber) => {
        this.setState({ results: null, searching: true, bootstrap: false, error: null });
        
        try {
            const results = await api.get(this.apiRoute + trackingNumber);
            
            if(results.error) {
                this.setState({
                    searching: false,
                    error: results.error,
                });
            } else {
                this.setState({
                    results,
                    searching: false,
                });
            }
        }
        catch(err) {
            this.setState({
                searching: false,
                error: err.userMessage || err.message || err,
            });
        };
	};

	render() {
		return (
			<div className="container">
				{!this.state.unauthorised && <SearchBox search={this.search}/>}
				{this.state.bootstrap
				 ? <HomePage />
				 : <Tracking
					 searching={this.state.searching}
					 results={this.state.results}
					 error={this.state.error}
				 />}
				<Footer/>
			</div>
		)
	};
};

export default App;
