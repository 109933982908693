import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Signature = ({image}) => (
    <img className="rm-signature" alt="Proof of Delivery" src={`data:image/svg+xml;base64,${btoa(image)}`}/>
);

Signature.propTypes = {
    image: PropTypes.string.isRequired,
};

export default Signature;
