import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Card from "Components/Card/index.js";
import Row from "Components/Card/Row/index.js";
import TrackingIcon from "Assets/Icons/TrackingNumber.png";
import LinkIcon from "Assets/Icons/Link.png";
import CalendarIcon from "Assets/Icons/Date.png";
import WeightIcon from "Assets/Icons/Weight.png";
import ClientIcon from "Assets/Icons/Client.png";
import CompanyIcon from "Assets/Icons/Company.png";
import HubIcon from "Assets/Icons/Hub.png";
import CarrierIcon from "Assets/Icons/Carrier.png";
import { P2PTRACKINGURL } from "Constants/tracking.js"

const P2PResults = ({data, onCopy}) => (
	<React.Fragment>
		<Card header="Summary" first>
			{data.trackingNumber && <Row 
				copy
				onCopy={onCopy}
				label="Tracking No."
				value={data.trackingNumber}
				icon={TrackingIcon}
			/>}
			{data.trackingNumber && <Row
				copy
				onCopy={onCopy}
				link
				label="Link"
				value={P2PTRACKINGURL + data.trackingNumber}
				icon={LinkIcon}
			/>}
			{data.expectedDeliveryDate ? <Row
				copy
				onCopy={onCopy}
				label="Deliv. Date"
				value={moment(data.expectedDeliveryDate).local().format("YYYY-MM-DD HH:mm")}
				icon={CalendarIcon}
			/> : null}
			{data.shipperReferenceId ? <Row
				copy
				onCopy={onCopy}
				label="Shipper Ref."
				value={data.shipperReferenceId}
				icon={TrackingIcon}
			/> : null}
			{data.userBrandingId ? <Row
				copy
				onCopy={onCopy}
				label="Client"
				value={data.userBrandingId}
				icon={ClientIcon}
			/> : null}
			{data.consigneeCompany ? <Row
				copy
				onCopy={onCopy}
				label="Consignee"
				value={data.consigneeCompany}
				icon={CompanyIcon}
			/> : null}
			{data.weight ? <Row
				label="Weight"
				value={`${data.weight}${data.weightUOM}`}
				icon={WeightIcon}
			/> : null}
			{data.originHub ? <Row
				copy
				onCopy={onCopy}
				label="Origin Hub"
				value={data.originHub}
				icon={HubIcon}
			/> : null}
		</Card>
		{data.carrierTrackingUrl ? <Card header="Carrier Details">
			{data.fmCarrierName ? <Row
				copy
				onCopy={onCopy}
				label="Name"
				value={data.fmCarrierName}
				icon={CarrierIcon}
			/> : null}
			{data.carrierTrackingNumber ? <Row
				copy
				onCopy={onCopy}
				label="Tracking No."
				value={data.carrierTrackingNumber}
				icon={TrackingIcon}
			/> : null}
			<Row
				copy
				onCopy={onCopy}
				link
				label="Link"
				value={data.carrierTrackingUrl}
				icon={LinkIcon}
			/>
		</Card> : null}
	</React.Fragment>
);

P2PResults.propTypes = {
    data: PropTypes.object.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default P2PResults;