import React from "react";
import PropTypes from "prop-types";
import Card from "Components/Card/index.js";
import Row from "Components/Card/Row/index.js";
import Signature from "Components/Results/RM/Signature/index.js";
import TrackingIcon from "Assets/Icons/TrackingNumber.png";
import LinkIcon from "Assets/Icons/Link.png";
import CalendarIcon from "Assets/Icons/Date.png";
import SummaryIcon from "Assets/Icons/Summary.png";
import LocationIcon from "Assets/Icons/Location.png";
import StatusIcon from "Assets/Icons/Status.png";
import ServiceIcon from "Assets/Icons/Service.png";
import SignatureIcon from "Assets/Icons/Signature.png";
import { RMTRACKINGURL } from "Constants/tracking.js"

const RMResults = ({data, onCopy}) => (
	<React.Fragment>
		<Card header="Summary" first >
			{data.trackingNumber && <Row 
				copy
				onCopy={onCopy}
				label="Tracking No."
				value={data.trackingNumber}
				icon={TrackingIcon}
			/>}
			{data.trackingNumber && <Row
				copy
				onCopy={onCopy}
				link
				label="Link"
				value={RMTRACKINGURL + data.trackingNumber}
				icon={LinkIcon}
			/>}
			{data.expectedDeliveryDate ? <Row
				copy
				onCopy={onCopy}
				label="Deliv. Date"
				value={data.expectedDeliveryDate}
				icon={CalendarIcon}
			/> : null}
			{data.expectedDeliveryTimeSlot ? <Row
				copy
				onCopy={onCopy}
				label="Deliv. Time"
				value={data.expectedDeliveryTimeSlot}
				icon={CalendarIcon}
			/> : null}
			{data.productName ? <Row
				copy
				onCopy={onCopy}
				label="Service"
				value={data.productName}
				icon={ServiceIcon}
			/> : null}
			{data.lastEventDateTime ? <Row
				copy
				onCopy={onCopy}
				label="Date"
				value={data.lastEventDateTime}
				icon={CalendarIcon}
			/> : null}
			{data.lastEventLocationName ? <Row
				copy
				onCopy={onCopy}
				label="Location"
				value={data.lastEventLocationName}
				icon={LocationIcon}
			/> : null}
			{data.statusCategory ? <Row
				copy
				onCopy={onCopy}
				label="Status"
				value={data.statusCategory}
				icon={StatusIcon}
			/> : null}
			{data.summaryLine && <Row
				copy
				onCopy={onCopy}
				nowrap
				label="Summary"
				value={data.summaryLine}
				icon={SummaryIcon}
			/>}
		</Card>
		{(data.signature && data.signature.signeeName) || (data.signature && data.signature.image) ?
		<Card
			header="Signature"
			signature={data.signature.image ? <Signature image={data.signature.image} /> : null}
		>
			{data.signature.signeeName && <Row
				copy
				onCopy={onCopy}
				label="Name"
				value={data.signature.signeeName}
				icon={SignatureIcon}
			/>}
			{data.signature.dateTime && <Row
				copy
				onCopy={onCopy}
				label="Date"
				value={data.signature.dateTime}
				icon={CalendarIcon}
			/>}
		</Card> : null}
	</React.Fragment>
);

RMResults.propTypes = {
    data: PropTypes.object.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default RMResults;