import DoddleResults from "Components/Results/Doddle/index.js";
import YodelResults from "Components/Results/Yodel/index.js";
import RMResults from "Components/Results/RM/index.js";
import P2PResults from "Components/Results/P2P/index.js";
import HermesResults from "Components/Results/Hermes/index.js";
import DPDResults from "Components/Results/DPD/index.js";

const TYPES = {
    DPD: DPDResults,
    P2P: P2PResults,
    Yodel: YodelResults,
    RoyalMail: RMResults,
    Doddle: DoddleResults,
    Hermes: HermesResults,
};
    
const getProvider = (provider) => {
    return TYPES[provider] || null;    
};
    
export default getProvider;