import React from "react";
import PropTypes from "prop-types";
import Link from "Components/Card/Link/index.js";
import Copy from "Components/Card/Copy/index.js";
import "./styles.scss";

const Row = ({link, nowrap, copy, history, onCopy, icon, label, value}) => (
	<tr>
        <th>
            <div className="icon-container">
                <div className="icon">
                    <img src={icon} alt="Icon" />
                </div>
                <div className="label">{label}</div>
            </div>
        </th>
        <td>
            <div className="copy-container">
                {link && <Link value={value} />}
                <div className={`label ${link ? "label-elipsis" : ""} ${nowrap ? "label-no-wrap" : ""}`}>
                    {value}
                </div>
                {copy && <Copy history={history} value={value} onCopy={onCopy}/>}
            </div>
        </td>
    </tr>
);

Row.propTypes = {
    nowrap: PropTypes.bool,
    link: PropTypes.bool,
    copy: PropTypes.bool,
    history: PropTypes.bool,
    onCopy: PropTypes.func,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
};

Row.defaultProps = {
    nowrap: false,
    link: false,
    copy: false,
    history: false,
};

export default Row;