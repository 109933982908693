const baseUrl = "https://tracking.api.thegeeks.io";
// remove baseUrl and move to setupProxy.js when working locally
const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
};

const requestDetails = details => ({
    headers,
    credentials: "include",
    ...details,
});
	
const apiFetch = async (url, details) => {
    try {
        const response = await fetch(baseUrl + url, requestDetails(details));
        return await response.json();
    } catch (error) {
        throw new Error("An unrecognised error has occured");
    }
};

export const get = async url => await apiFetch(url);

export default {
    get,
};