import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const SafePlace = ({image}) => (
    <img className="hermes-safe-place" alt="Proof of Delivery" src={`data:image/png;base64,${image}`}/>
);

SafePlace.propTypes = {
    image: PropTypes.string.isRequired,
};

export default SafePlace;
