import React from "react";
import "./styles.scss";

const CopiedContainer = () => (
    <div className="copied-container">
        <div>
            Copied!
        </div>
    </div>
);

export default CopiedContainer;