import React from "react";
import TheGeeksLogo from "Assets/Logos/TheGeeksPoweredBy.png";
import "./styles.scss";

const Footer = () => (
    <div className="footer-logo">
        <img className="image" src={TheGeeksLogo} alt="TheGeeks Logo" />
    </div>
);

export default Footer;
