import React from "react";
import PropTypes from "prop-types";
import Events from "Components/Card/Events/index.js";
import getProvider from "Utils/providerMap.js";

const Results = ({results, onCopy}) => {

    const ProviderComponent = getProvider(results.serviceProvider);
    return (
        <React.Fragment>
            <ProviderComponent
                data={results}
                onCopy={onCopy}
            />
            {results.events ? <Events
                header="History"
                subheader="Last Scan"
                events={results.events} 
                onCopy={onCopy}
            /> : null}
        </React.Fragment>
    );
};

Results.propTypes = {
    results: PropTypes.object.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default Results;