import React from "react";
import PropTypes from "prop-types";
import EventDateTime from "Components/Card/Event/EventDateTime/index.js";
import EventLocationNote from "Components/Card/Event/EventLocationNote/index.js";
import EventDescription from "Components/Card/Event/EventDescription/index.js"
import "./styles.scss";

const Event = ({data, first, notlast}) => (
	<React.Fragment>
        {data.location || data.description ? <React.Fragment>
            <EventDateTime dateTime={data.dateTime} />
            <EventLocationNote value={data.note} />
            <EventLocationNote value={data.location} />
            <EventDescription description={data.description} first={first}/> 
        </React.Fragment> : null}
		{notlast &&
			<div className="elipsis">
				<div></div>
			</div>
		}
	</React.Fragment>
);

Event.propTypes = {
    data: PropTypes.object.isRequired,
    first: PropTypes.bool,
    notlast: PropTypes.bool,
};

Event.defaultProps = {
    first: false,
    notlast: false,
};

export default Event;