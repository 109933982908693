import React from "react";
import PropTypes from "prop-types";
import Event from "Components/Card/Event/index.js";

const EventList = ({events}) => {
    return (
        <React.Fragment>
            {events.map((event, i) => {
                if(events[0] === event) {
                    if(events.length === 1) {
                        return <Event key={i} data={event} first />
                    } else {
                        return <Event key={i} data={event} first notlast />
                    }
                } else if(event !== events[events.length-1]) {
                    return <Event key={i} data={event} notlast />;
                } else {
                    return <Event key={i} data={event} />
                }
            })}
        </React.Fragment>
    );
}

EventList.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default EventList;