import React from "react";
import AboutUs from "Components/AboutUs/index.js";
import SupportedCarriers from "Components/SupportedCarriers/index.js";
import "./styles.scss";

const HomePage = () => (
    <div className="home-page-container">
        <AboutUs />
        <SupportedCarriers />
    </div>
);

export default HomePage;
