import React from "react";
import Logo from "Assets/Logos/RMGreyscale.png";
import "./styles.scss";

const RMLogo = () => (
	<div className="rm-logo-container">
        <img src={Logo} alt="Royal Mail Logo" />
    </div>
);

export default RMLogo;