import React from "react";
import Logo from "Assets/Logos/YodelGreyscale.png";
import "./styles.scss";

const YodelLogo = () => (
	<div className="yodel-logo-container">
        <img src={Logo} alt="Yodel Logo" />
    </div>
);

export default YodelLogo;