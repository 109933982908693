import React from "react";
import PropTypes from "prop-types";
import Card from "Components/Card/index.js";
import Row from "Components/Card/Row/index.js";
import TrackingIcon from "Assets/Icons/TrackingNumber.png";
import SummaryIcon from "Assets/Icons/Summary.png";
import ServiceIcon from "Assets/Icons/Service.png";
import LinkIcon from "Assets/Icons/Link.png";
import { DPDTRACKINGURL } from "Constants/tracking.js"

const HermesResults = ({data, onCopy}) => (
	<React.Fragment>
		<Card header="Summary" first>
			{data.trackingNumber && <Row 
				copy
				onCopy={onCopy}
				label="Tracking No."
				value={data.trackingNumber}
				icon={TrackingIcon}
            />}
			{data.lastEventStatus && <Row
				copy
				onCopy={onCopy}
				label="Last Update"
				value={data.lastEventStatus}
				icon={SummaryIcon}
			/>}
			{data.parcelCode && <Row
				copy
				onCopy={onCopy}
				link
				nowrap
				label="Link"
				value={DPDTRACKINGURL + data.parcelCode}
				icon={LinkIcon}
			/>}
            {data.deliveryAddress1 ? <Row
				copy
				onCopy={onCopy}
				label="Line 1"
				value={data.deliveryAddress1}
				icon={ServiceIcon}
			/> : null}
			{data.deliveryAddress2 ? <Row
				copy
				onCopy={onCopy}
				label="Line 2"
				value={data.deliveryAddress2}
				icon={ServiceIcon}
			/> : null}
			{data.deliveryLocality ? <Row
				copy
				onCopy={onCopy}
				label="Locality"
				value={data.deliveryLocality}
				icon={ServiceIcon}
			/> : null}
			{data.deliveryCity ? <Row
				copy
				onCopy={onCopy}
				label="City"
				value={data.deliveryCity}
				icon={ServiceIcon}
			/> : null}
			{data.deliveryPostcode ? <Row
				copy
				onCopy={onCopy}
				label="Postcode"
				value={data.deliveryPostcode}
				icon={ServiceIcon}
			/> : null}
			{data.deliveryCountryCode ? <Row
				copy
				onCopy={onCopy}
				label="Country"
				value={data.deliveryCountryCode}
				icon={ServiceIcon}
			/> : null}
		</Card>
	</React.Fragment>
);

HermesResults.propTypes = {
    data: PropTypes.object.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default HermesResults;