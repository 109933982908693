import React, { useState } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import CopiedContainer from "Components/CopiedContainer/index.js";
import ResultsLogo from "Components/Results/Shared/Logo/index.js";
import Spinner from "Components/Spinner/index.js";
import Results from "Components/Results/index.js";
import Error from "Components/Error/index.js";
import "./styles.scss";

const Tracking = ({results, error, searching}) => {
	const [copied, setCopied] = useState(false);
	const onCopy = (e, value) => {
		e.preventDefault();
		setCopied(true);

		const el = document.createElement("textarea");
		el.value = value;
		el.setAttribute("readonly", "");
		el.style.position = "absolute";
		el.style.left = "-9999px";
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);

		setTimeout(function(){ setCopied(false) }, 900);
	}

    return (
        <React.Fragment>
            {results && !copied && <ResultsLogo provider={results.serviceProvider} />}
            {copied && <CopiedContainer />}
            <Scrollbars className="scrollbars">
                <div className="tracking-container">
                    {searching && <Spinner/>}
                    {error && <Error message={error}/>} 
                    {results && <Results results={results} onCopy={onCopy} />}
                </div>
            </Scrollbars>
        </React.Fragment>
    );
}

Tracking.propTypes = {
    results: PropTypes.object,
    error: PropTypes.string,
    searching: PropTypes.bool.isRequired,
};

export default Tracking;
