import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Card from "Components/Card/index.js";
import Row from "Components/Card/Row/index.js";
import CalendarIcon from "Assets/Icons/Date.png";
import TrackingIcon from "Assets/Icons/TrackingNumber.png";
import SignatureIcon from "Assets/Icons/Signature.png";
import Signature from "Components/Results/Hermes/Signature/index.js";
import SafePlace from "Components/Results/Hermes/SafePlace/index.js";

const HermesResults = ({data, onCopy}) => (
	<React.Fragment>
		<Card header="Summary" first>
			{data.trackingNumber && <Row 
				copy
				onCopy={onCopy}
				label="Tracking No."
				value={data.trackingNumber}
				icon={TrackingIcon}
            />}
            {data.expectedDeliveryDate ? <Row
				copy
				onCopy={onCopy}
				label="Deliv. Date"
				value={moment(data.expectedDeliveryDate).local().format("YYYY-MM-DD HH:mm")}
				icon={CalendarIcon}
			/> : null}
			{data.expectedDeliveryTimeSlot ? <Row
				copy
				onCopy={onCopy}
				label="Deliv. Time"
				value={data.expectedDeliveryTimeSlot}
				icon={CalendarIcon}
			/> : null}
		</Card>
        {(data.signature && data.signature.signeeName) || (data.signature && data.signature.image) ?
		<Card
			header="Signature"
			signature={data.signature.image ? <Signature image={data.signature.image} /> : null}
		>
			{data.signature.signeeName && <Row
				copy
				onCopy={onCopy}
				label="Name"
				value={data.signature.signeeName}
				icon={SignatureIcon}
			/>}
        </Card> : null}
        {(data.safePlace && data.safePlace.signeeName) || (data.safePlace && data.safePlace.image) ?
		<Card
			header="Safe Place"
			signature={data.safePlace.image ? <SafePlace image={data.safePlace.image} /> : null}
		>
			{data.safePlace.signeeName && <Row
				copy
				onCopy={onCopy}
				label="Name"
				value={data.safePlace.signeeName}
				icon={SignatureIcon}
			/>}
		</Card> : null}
	</React.Fragment>
);

HermesResults.propTypes = {
    data: PropTypes.object.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default HermesResults;