import React from "react";
import P2PLogo from "Assets/Logos/P2PGreyscale.png";
import YodelLogo from "Assets/Logos/YodelGreyscale.png";
import RMLogo from "Assets/Logos/RMGreyscale.png";
import DPDLogo from "Assets/Logos/DPDGreyscale.png";
import "./styles.scss";

const SupportedCarriers = () => (
    <div className="init-container">
        <div className="yodel-logo">
            <img src={YodelLogo} alt="Yodel Logo"/>
        </div>
        <div className="dpd-logo">
            <img src={DPDLogo} alt="DPD Logo" />
        </div>
        <div className="p2p-logo">
            <img src={P2PLogo} alt="P2P Logo" />
        </div>
        <div className="rm-logo">
            <img src={RMLogo} alt="Royal Mail Logo"/>
        </div>
    </div>
);

export default SupportedCarriers;