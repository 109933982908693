import React from "react";
import Logo from "Assets/Logos/DPDGreyscale.png";
import "./styles.scss";

const DPDLogo = () => (
	<div className="dpd-logo-container">
        <img src={Logo} alt="DPD Logo" />
    </div>
);

export default DPDLogo;