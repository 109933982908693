import React from "react";
import PropTypes from "prop-types";
import Card from "Components/Card/index.js";
import Row from "Components/Card/Row/index.js";
import Signature from "Components/Results/Yodel/Signature/index.js";
import TrackingIcon from "Assets/Icons/TrackingNumber.png";
import StopsIcon from "Assets/Icons/Stops.png";
import CalendarIcon from "Assets/Icons/Date.png";
import CollectedIcon from "Assets/Icons/Collected.png";
import AddressOnlyIcon from "Assets/Icons/AddressOnly.png";
import DeliveredIcon from "Assets/Icons/Delivered.png";
import PODRequiredIcon from "Assets/Icons/PODRequired.png";
import InstructionsIcon from "Assets/Icons/Instructions.png";
import CarrierIcon from "Assets/Icons/Carrier.png";
import TelephoneIcon from "Assets/Icons/Telephone.png";
import SignatureIcon from "Assets/Icons/Signature.png";

const YodelResults = ({data, onCopy}) => (
	<React.Fragment>
		<Card header="Summary" first >
			{data.trackingNumber && <Row 
				copy
				onCopy={onCopy}
				label="Tracking No."
				value={data.trackingNumber}
				icon={TrackingIcon}
			/>}
			{data.expectedDeliveryDate ? <Row
				copy
				onCopy={onCopy}
				label="Deliv. Date"
				value={data.expectedDeliveryDate}
				icon={CalendarIcon}
			/> : null}
			{data.specialInstructions ? <Row
				copy
				onCopy={onCopy}
				nowrap
				label="Special Instr."
				value={data.specialInstructions}
				icon={InstructionsIcon}
			/> : null}
			{data.completedStops ? <Row
				label="Stops"
				value={`${data.completedStops}/${data.totalStops}`}
				icon={StopsIcon}
			/> : null}
			{data.addressOnly ? <Row
				label="Address Only Delivery?"
				value={data.addressOnly ? "✔" : "✖"}
				icon={AddressOnlyIcon}
			/> : null}
			{data.collected ? <Row
				label="Collected"
				value={data.collected ? "✔" : "✖"}
				icon={CollectedIcon}
			/> : null}
			{data.delivered ? <Row
				label="Delivered"
				value={data.delivered ? "✔" : "✖"}
				icon={DeliveredIcon}
			/> : null}
			{data.podRequired ? <Row
				label="Require POD?"
				value={data.podRequired ? "✔" : "✖"}
				icon={PODRequiredIcon}
			/> : null}
		</Card>
		{data.courierName ? <Card header="Courier Details">
			{data.courierName ? <Row
				copy
				onCopy={onCopy}
				label="Name"
				value={data.courierName}
				icon={CarrierIcon}
			/> : null}
			{data.courierTelephone ? <Row
				copy
				onCopy={onCopy}
				label="Telephone No."
				value={data.courierTelephone}
				icon={TelephoneIcon}
			/> : null}
		</Card> : null}
		{(data.signature && data.signature.signeeName) || (data.signature && data.signature.image) ?
		<Card
			header="Signature"
			signature={data.signature.image ? <Signature image={data.signature.image} /> : null}
		>
			{data.signature.signeeName && <Row
				copy
				onCopy={onCopy}
				label="Name"
				value={data.signature.signeeName}
				icon={SignatureIcon}
			/>}
			{data.signature.dateTime && <Row
				copy
				onCopy={onCopy}
				label="Date"
				value={data.signature.dateTime}
				icon={CalendarIcon}
			/>}
		</Card> : null}
	</React.Fragment>
);

YodelResults.propTypes = {
    data: PropTypes.object.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default YodelResults;