import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const EventDescription = ({description, first}) => (
    <React.Fragment>
        {description &&
			<div className="status">
				{first && <span className="first-status">{description}</span>}
				{!first && description}
			</div>
		}
    </React.Fragment>
);

EventDescription.propTypes = {
    description: PropTypes.string,
    first: PropTypes.bool,
};

EventDescription.defaultProps = {
    first: false,
};

export default EventDescription;